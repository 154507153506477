.pro-card {
    padding: 30px;
    background: #FFFFFF;
    /* margin-top: 10%; */
}

.pro-card:hover {
    background: #F5F5F5;
}

.pro-card .brand-img {
    width: 100%;
    border-radius: 25px;
}

.pro-card .main-pro-card {
    border: none;
    background: transparent;
}

.pro-card .main-pro-card .card-title {
    font-size: 18px;
}

.pro-card .main-pro-card .card-txttt {
    font-size: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 3;
    line-clamp: 5;
    text-align: justify;
}

.inquiry-btn {
    border: 1px solid #000000;
    background: #FFFFFF;
    font-size: 16px;
    color: #000000;
    width: 100%;
    font-weight: 600;
    padding: 10px;
    margin-top: 3%;
    border-radius: 6px;




    -webkit-transition: color 0.3s ease-in-out;
    -moz-transition: color 0.3s ease-in-out;
    -ms-transition: color 0.3s ease-in-out;
    -o-transition: color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out;


    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    display: inline-block;
    position: relative;

    vertical-align: middle;
    color: black;
    z-index: 1;
    overflow: hidden;
}

.inquiry-btn:before {
    /* transitions på :before og :after er ikke støttet i opera og safari ennå */
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    padding-top: 0;
    z-index: -1;

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    border-radius: 100%;
    background: #d66800;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.inquiry-btn:hover:before {
    width: 105%;
    padding-top: 105%;
}


.inquiry-btn:hover {
    /* background: transparent linear-gradient(180deg, #D66800 0%, #FF7C00 100%) ; */
    color: #FFFFFF;
    border: 1px solid #FF7C00;
}


.pro-card .main-pro-card .sub-main-img {
    overflow: hidden;
    width: 100%;
    border-radius: 20px;
    box-sizing: border-box;
    display: block;
}

.pro-card .main-pro-card .sub-main-img .brand-img {
    position: relative;
    background: no-repeat;
    width: 100%;
    height: 250px;
    display: block;
    transition: 0.4s;
    height: 268px;
    object-fit: contain;
}

.pro-card .main-pro-card .sub-main-img:hover .brand-img {
    transform: scale(1.1);
}

/* media */

@media(min-width:0px) and (max-width:576px) {
    .inquiry-btn {
        font-size: 10px;
        padding: 6px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .inquiry-btn {
        font-size: 12px;
        padding: 6px;
    }
}

@media(min-width:486px) and (max-width:576px) {}

@media(min-width:577px) and (max-width:768px) {}

@media(min-width:769px) and (max-width:992px) {}

@media(min-width:992px) and (max-width:1200px) {}