.explore-trendong {
    background: #FFF8F2;
    padding: 3%;
}

 /* .title-common h3 {
    font-size: 40px;
    font-weight: 600;
    color: #343434;
    text-align: center;
} */