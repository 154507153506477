.header-section {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 26px 37px #00000012;
  opacity: 1;
  height: 60px;
  position: sticky;
  top: 0px;
  z-index: 999;
}

.header-section .header-main .header-img .header-logo {
  width: auto;
  height: 50px;
  background-color: transparent;
  opacity: 1;
}

.header-section .header-main .header-lists2 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  letter-spacing: 0px;
  display: flex;
  color: #000000;
  opacity: 1;
  margin: 0px 16px;
  align-content: center;
  align-items: center;
}

.header-section .emailUs {
  background: transparent linear-gradient(180deg, #1D44AD 0%, #1553F9 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #00000029;
  border-radius: 4px;
  border: none;
  padding: 5px 0px;
  width: 95px;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
}

.header-section .header-main .btndiv {
  height: 57px;
  width: 112px;
  background-color: #000000;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  line-height: 21px;
  letter-spacing: 0.18px;
  color: #ffff !important;
  opacity: 1;
  justify-content: center;
  align-content: center;
}

.header-section .header-main .btndiv .btnss {
  width: 100%;
  height: 100%;
  background-color: #000000;
  color: #ffffff;
  border: none;
}

.header-section .header-main span.navbar-toggler-icon {
  font-size: 15px;
}

.header-section .header-main .navbar-toggler:focus {
  box-shadow: none;
}

.header-section .header-main .nav-link {
  font-size: 15px;
  margin-left: 0;
  font-weight: 600;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.header-section .header-main .nav-link.active {
  border-bottom: 4px solid #F88D28;
}

/* dropdown css */
.header-section .header-main img.dropdown-img {
  width: 100%;
  height: 150px;
  opacity: 1;
}

.header-section .header-main .dropdown-menu.show {
  width: 723px;
  height: 261px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 9px 37px #0000000a;
  border: none;
  opacity: 1;
  position: absolute;
  top: 90px;
  left: -256px;
  transform: translateY(0);
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.header-section .header-main .navbMainOne .nav-link {
  margin-left: 40px;
}

.header-section .header-main .navbMainTwo .nav-link {
  margin-left: 10px;
}

.header-section .header-main .SearchinputMain {
  position: relative;
}

.header-section .header-main .SearchinputMain .searchicon {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translate(0%, -50%);
}

.header-section .header-main .form-control {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: 0;
  text-align: end;
  width: 344px;
}

.header-section .header-main .form-control:focus {
  box-shadow: unset;
}

@keyframes slide-in-top {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.header-section .header-main .dropdown-mains .row {
  width: 100%;
}

.header-section .header-main .dropdown-mains .dp-text {
  padding-top: 11px;
}

.header-section .header-main .dropdown-mains .dp-txt-p {
  font: normal normal medium 14px/21px Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.14px;
  color: #000000;
  opacity: 1;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.header-section .header-main .dropdown-mains .drop-links {
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.18px;
  margin-bottom: 5px;
  color: #000000;
  opacity: 1;
}

.header-section .header-main .dropdown-item:focus,
.header-section .header-main .dropdown-item:hover {
  background-color: #fff;
}

.header-section .header-main .dropdown-mains .aro-icn {
  color: #000000;
  transition: margin-left 0.3s ease, color 0.3s ease;
}

.header-section .header-main .dropdown-mains .dp-icn .aro-icn:hover {
  margin-left: 10px;
  color: #ffc400;
}

/* .header-section .header-main .dropdown-mains {
    padding-top: 3px;
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  } */

/* @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
      transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  } */

.header-section .header-main .rightside-border {
  width: 100%;
  height: 100%;
  border-right: 1px solid #00000017;
}

.header-section .navbar-toggler {
  margin-right: 15px !important;
  border: none;
}

.header-section .header-main .navbMainTwo .mobile_view_icon {
  display: flex;
}

.header-section .header-main .mobile_view_icon_holder {
  display: none;
}

@media (max-width: 991px) {
  .header-section .header-main .navbMainTwo {
    display: ruby;
  }

  .header-section .header-main .mobile_view_icon_holder {
    display: block;
  }

  .header-section .header-main .desktop_view_icon_holder {
    display: none;
  }
}

@media (min-width: 0px) and (max-width: 320px) {


  /* .header-section .header-main .SearchinputMain {
    width: 145px;
  } */
  nav.p-0.m-0.navbar.navbar-expand-lg.navbar-light {
    background: #fff;
  }

  .header-section .header-main .navbMainTwo .nav-link {
    margin-left: 6px;
  }

  .header-section .header-main .xs-view {
    display: block;
    margin-right: 15px;
  }

  .header-section .header-main .lg_view {
    display: none;
  }
}

@media (min-width: 321px) and (max-width: 390px) {

  .header-section .header-main .xs-view {
    display: block;
    margin-right: 15px;
  }

  .header-section .header-main .lg_view {
    display: none;
  }
}

@media (min-width: 404px) and (max-width: 991px) {

  .header-section .header-main .xs-view {
    display: none !important;
  }
}

@media (min-width: 391px) and (max-width: 404px) {
  /* .header-section .header-main .SearchinputMain {
    width: 140px;
  } */

  .header-section .header-main .xs-view {
    display: block;
    margin-right: 15px;
  }

  .header-section .header-main .lg_view {
    display: none;
  }
}

@media (min-width: 321px) and (max-width: 404px) {

  /* .header-section .header-main .SearchinputMain {
      width: 100px;
  } */
  nav.p-0.m-0.navbar.navbar-expand-lg.navbar-light {
    background: #fff;
  }

  /* .header-section .header-main .navbMainTwo .nav-link {
    margin-left: 3px;
  } */
}

@media (min-width: 405px) and (max-width: 485px) {
  .header-section .header-main .navbMainTwo .nav-link {
    margin: 0px 3px;
  }
}

@media (min-width: 405px) and (max-width: 469px) {
  .header-section .header-main .SearchinputMain {
    width: 139px;
  }
}

/* .header-section .header-main .SearchinputMain {
    position: relative;
    width: 151px;
} */

@media (min-width: 0px) and (max-width: 485px) {
  .header-section {
    height: auto;
  }

  .header-section .header-main .header-lists {
    font-size: 12px;
    line-height: 12px;
    margin-left: 0px;
  }

  .header-section .header-main .header-lists2 {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    margin: 0px 0px;
  }

  .header-section .header-main .btndiv {
    height: 30px;
    width: 80px;
    font-size: 12px;
    line-height: 12px;
  }

  .header-section .header-main .dropdown-menu.show {
    width: 250px;
    height: auto;
    left: 0;
  }

  .header-section .header-main .dropdown-mains {
    padding-left: 21px;
  }

  .header-section .header-main .dp-mains {
    margin-bottom: 15px;
  }

  .header-section .header-main .dropdown-mains .dp-txt-p {
    font-size: 11px;
    line-height: 16px;
  }

  .header-section .header-main .dropdown-mains .drop-links {
    font-size: 11px;
    line-height: 11px;
  }

  .header-section .header-main img.dropdown-img {
    height: auto;
  }

  .header-section .header-main .navbar-collapse {
    background: aliceblue;
  }
}

@media (min-width: 486px) and (max-width: 575px) {
  .header-section {
    height: auto;
  }

  .header-section .header-main .header-lists {
    font-size: 13px;
    line-height: 13px;
    margin-left: 0px;
  }

  .header-section .header-main .header-lists2 {
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    margin: 0px 0px;
  }

  .header-section .header-main .navbMainTwo .nav-link {
    margin: 0px 4px;
  }

  .header-section .header-main .btndiv {
    height: 35px;
    width: 95px;
    font-size: 13px;
    line-height: 13px;
  }

  .header-section .header-main .navbar-collapse {
    background: aliceblue;
  }

  .header-section .header-main .dropdown-menu.show {
    width: 250px;
    height: auto;
    left: 0;
    top: 95px;
  }

  .header-section .header-main .dropdown-mains {
    padding-left: 21px;
  }

  .header-section .header-main .dp-mains {
    margin-bottom: 15px;
  }

  .header-section .header-main .dropdown-mains .dp-txt-p {
    font-size: 11px;
    line-height: 16px;
  }

  .header-section .header-main .dropdown-mains .drop-links {
    font-size: 11px;
    line-height: 11px;
  }

  .header-section .header-main img.dropdown-img {
    height: auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .header-section .header-main .header-lists {
    font-size: 14px;
    line-height: 14px;
    margin-left: 0px;
  }

  .header-section .header-main .navbar-collapse {
    background: aliceblue;
  }

  .header-section .header-main .header-lists2 {
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    margin: 0px 0px;
  }

  .header-section .header-main .btndiv {
    height: 35px;
    width: 95px;
    font-size: 14px;
    line-height: 14px;
  }

  .header-section .header-main .dropdown-menu.show {
    width: 350px;
    height: auto;
    position: absolute;
    left: 0;
    top: 100px;
  }

  .header-section .header-main .dropdown-mains {
    padding-left: 21px;
  }

  .header-section .header-main .dp-mains {
    margin-bottom: 15px;
  }

  .header-section .header-main .dropdown-mains .dp-txt-p {
    font-size: 13px;
    line-height: 18px;
  }

  .header-section .header-main .dropdown-mains .drop-links {
    font-size: 13px;
    line-height: 13px;
  }

  .header-section .header-main img.dropdown-img {
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .header-section .header-main .header-lists {
    font-size: 15px;
    line-height: 17px;
    margin-left: 0px;
  }

  .header-section .header-main .header-lists2 {
    font-size: 15px;
    line-height: 17px;
    font-weight: 500;
    margin: 0px 0px;
  }

  .header-section .header-main .navbar-collapse {
    background: aliceblue;
  }

  .header-section .header-main .btndiv {
    height: 40px;
    width: 115px;
    font-size: 15px;
    line-height: 17px;
  }

  .header-section .header-main .dropdown-menu.show {
    width: 550px;
    height: auto;
    position: absolute;
    left: 0;
    top: 110px;
  }

  .header-section .header-main img.dropdown-img {
    width: 100%;
    height: auto;
    opacity: 1;
  }

  .header-section .header-main .dropdown-mains .drop-links {
    font-size: 13px;
    line-height: 13px;
  }

  .header-section .header-main .dropdown-mains .dp-txt-p {
    font-size: 13px;
    line-height: 18px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .header-section .header-main .header-lists {
    font-size: 14px;
    line-height: 16px;
  }

  .header-section .header-main .header-lists2 {
    font-size: 14px;
    line-height: 16px;
  }

  .header-section .header-main .btndiv {
    height: 56px;
    width: 125px;
    font-size: 14px;
    line-height: 16px;
  }

  .header-section .header-main .dropdown-menu.show {
    left: -400px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .header-section .header-main .dropdown-menu.show {
    left: -340px;
  }
}