.customerstories_section {
  background-image: url(../../../../public/assets/Homepage/Review/reviewbacgorund.png);
  padding: 25px 0px;
  margin: 80px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.customerstories_section .cus_afterheading {
  margin-top: 60px;
}

.customerstories_section .customerstories_main .reviewdetail_div {
  position: relative;
}

.customerstories_section .customerstories_main .starsrating_div {
  position: absolute;
  bottom: 5px;
  right: 10px;
}

.customerstories_section .customerstories_main .reviewdisplay {
  display: flex;
}

.customerstories_section .customerstories_main .reviewdisplay_img {
  width: 100%;
  height: 264px;
}

.customerstories_section .customerstories_main .custstories_heading {
  text-align: center;
  font-size: 40px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #343434;
  opacity: 1;
}

.customerstories_section .customerstories_main .cus_subheading {
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
}

.customerstories_section .customerstories_main .reviewdetail_div {
  width: 100%;
  height: 264px;
  background: transparent
    linear-gradient(90deg, #ffffff 0%, #ffffff 0%, #bfcffb 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1;
  padding: 10px;
}

.customerstories_section .customerstories_main .reviewer_name {
  margin-top: 40px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.customerstories_section .customerstories_main .reviewtexts {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
}

.customerstories_section .customerstories_main .swiperimag {
  width: 100%;
  height: 196px;
}

.customerstories_section .customerstories_main .pauseicn {
  font-size: 37px;
}


.customerstories_section .customerstories_main  .swipersimages {
  position: relative;
}

.customerstories_section .silder-btn {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.customerstories_section .silder-btn .back-btn {
  width: 32px;
  height: 32px;
  margin: 0px 14px;
  background-color: #ffffff;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute;
    bottom: -13px;
    left: 212px; */
  z-index: 9999;
}

.customerstories_section .silder-btn .next-btn {
  width: 32px;
  height: 32px;
  margin: 0px 14px;
  background-color: #ffffff;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* position: absolute;
    bottom: -13px;
    right: 212px; */
  z-index: 9999;
  transform: rotate(180deg);
}

@media (min-width: 0px) and (max-width: 575px) {
  .customerstories_section {
    margin: 40px 0px;
  }

  .customerstories_section .cus_afterheading {
    margin-top: 20px;
  }

  .customerstories_section .customerstories_main .custstories_heading {
    font-size: 27px;
  }

  .customerstories_section .customerstories_main .cus_subheading {
    font-size: 12px;
  }

  .customerstories_section .customerstories_main .reviewtexts {
    font-size: 13px;
  }

  .customerstories_section .customerstories_main .reviewer_name {
    font-size: 20px;
  }

  .customerstories_section .customerstories_main .swiperimag {
    height: 170px;
  }

  .customerstories_section .customerstories_main .reviewdetail_div {
    height: 240px;
  }

  .customerstories_section .customerstories_main .reviewdisplay_img {
    height: 364px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .customerstories_section {
    margin: 40px 0px;
  }

  .customerstories_section .cus_afterheading {
    margin-top: 30px;
  }

  .customerstories_section .customerstories_main .custstories_heading {
    font-size: 29px;
  }

  .customerstories_section .customerstories_main .cus_subheading {
    font-size: 13px;
  }

  .customerstories_section .customerstories_main .reviewtexts {
    font-size: 13px;
  }

  .customerstories_section .customerstories_main .reviewer_name {
    font-size: 20px;
  }

  .customerstories_section .customerstories_main .swiperimag {
    height: 170px;
  }

  .customerstories_section .customerstories_main .reviewdetail_div {
    height: 240px;
  }

  .customerstories_section .customerstories_main .reviewdisplay_img {
    height: 240px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .customerstories_section {
    margin: 40px 0px;
  }

  .customerstories_section .cus_afterheading {
    margin-top: 30px;
  }

  .customerstories_section .customerstories_main .custstories_heading {
    font-size: 33px;
  }

  .customerstories_section .customerstories_main .cus_subheading {
    font-size: 14px;
  }

  .customerstories_section .customerstories_main .reviewtexts {
    font-size: 14px;
  }

  .customerstories_section .customerstories_main .reviewer_name {
    font-size: 22px;
  }

  .customerstories_section .customerstories_main .swiperimag {
    height: 200px;
  }
}
