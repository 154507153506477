.first-rating .main-below-txtt .girl-img {
    width: 100%;
    margin-top: 3%;
    /* position: relative; */
}

.first-rating .main-below-txtt {
    position: relative;
}

.first-rating .main-below-txtt .below-txtt {
    position: absolute;
   
    left: 47px;
    bottom: 10px;
}

.first-rating .below-txtt .reviews-txtttt {
    font-size: 18px;
    font-weight: 600;
    color: #FFF;
}

.first-rating .star-img {
    /* font-size: 34px; */
}

.first-rating .circulkarProgress {
    width: 100px;
}


/* media */

@media(min-width:0px) and (max-width:576px) {
    .first-rating .below-txtt .reviews-txtttt {
        font-size: 14px;
    }

    .first-rating .circulkarProgress {
        width: 50px;
    }

    .first-rating .main-below-txtt .below-txtt {
       
        left: 47px;
    }

    .first-rating .main-below-txtt .girl-img {
        height: 216px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .first-rating .below-txtt .reviews-txtttt {
        font-size: 14px;
    }

    .first-rating .circulkarProgress {
        width: 50px;
    }
}

@media(min-width:768px) and (max-width:992px) {
    .first-rating .below-txtt .reviews-txtttt {
        font-size: 14px;
    }

    .first-rating .circulkarProgress {
        width: 50px;
    }
}

@media(min-width:993px) and (max-width:1024px) {
    .first-rating .below-txtt .reviews-txtttt {
        font-size: 14px;
    }

    .first-rating .circulkarProgress {
        width: 50px;
    }

    .first-rating .main-below-txtt .below-txtt {
       
        left: 47px;
    }
}