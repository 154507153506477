.lookingfor_section{
    background: transparent url('../../../../public/assets/Homepage/Review/lookingforbackground.png') 0% 0% no-repeat padding-box;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
   padding: 50px 0px 30px 0px;

}


.lookingfor_section .looking_heading {
  text-align: center;
  font-size: 40px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #000000;
  text-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin-bottom: 40px;
}

.inquiryformbackground .form-control{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 6px;
    text-align: left;
    font-size: 12px;
    color: #847C7C;
}

@media (min-width: 0px) and (max-width: 575px){

    .lookingfor_section{
       padding: 30px 0px 20px 0px;
    
    }
    
    .lookingfor_section .looking_heading {
        font-size: 22px;
        margin-bottom: 20px;
    }
}

@media (min-width: 576px) and (max-width: 767px){
    .lookingfor_section{
        padding: 30px 0px 20px 0px;
     
     }
    .lookingfor_section .looking_heading {
        font-size: 24px;
        margin-bottom: 20px;
    }
}
@media (min-width: 576px) and (max-width: 767px){
    .lookingfor_section{
        padding: 30px 0px 20px 0px;
     
     }
    .lookingfor_section .looking_heading {
        font-size: 26px;
        margin-bottom: 20px;
    }
}
