 .mySwiper .swiper-pagination-bullet {
    background: #858585;
    margin: 3px !important;
    border-radius: 5px;
    width: 6px;
    height: 6px;
}

.mySwiper .swiper-pagination-bullet-active {
    background: #FF8C28;
    border-radius: 5px;
}