.inquiry_section {
  background-image: url('../../../../public/assets/Homepage/Banner/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 50px;
}

.inquiry_section .inquiry_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
}

.inquiry_section .inquiry_main .inquiry_text {
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #ffffff;
}

.inquiry_section .inquiry_main .inquiry_button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 300;
  opacity: 1;
  color: #000000;
  width: 292px;
  padding: 10px 0px;

  -webkit-transition: color 0.4s ease-in-out;
  -moz-transition: color 0.4s ease-in-out;
  -ms-transition: color 0.4s ease-in-out;
  -o-transition: color 0.4s ease-in-out;
  transition: color 0.4s ease-in-out;


  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  display: inline-block;
  position: relative;

  vertical-align: middle;
  color: black;
  z-index: 1;
  overflow: hidden;
}

.inquiry_section .inquiry_main .inquiry_button:before {
  /* transitions på :before og :after er ikke støttet i opera og safari ennå */
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  padding-top: 0;
  z-index: -1;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  border-radius: 100%;
  background: #d66800;

  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.inquiry_section .inquiry_main .inquiry_button:hover:before {
  width: 105%;
  padding-top: 105%;
}

.inquiry_section .inquiry_main .inquiry_button:hover {
  /* background: transparent linear-gradient(180deg, #d66800 0%, #ff7c00 100%) 0%
    0% no-repeat padding-box; */
  color: #fff;
}

@media (min-width: 0px) and (max-width: 575px) {
  .inquiry_section .inquiry_main .inquiry_text {
    font-size: 21px;
  }

  .inquiry_section .inquiry_main {
    display: unset;
  }

  .inquiry_section .inquiry_main .inquiry_button {
    width: 100%;
    font-size: 13px;
    padding: 10px;
    margin-top: 10px;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .inquiry_section .inquiry_main .inquiry_text {
    font-size: 21px;
  }

  .inquiry_section .inquiry_main .inquiry_button {
    width: 150px;
    font-size: 13px;
    padding: 10px;
    margin-top: 10px;
  }

  .inquiry_section .inquiry_main {
    padding: 50px 0px;
  }
}