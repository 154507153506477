.view-less-review {
    background: #F2F6FF 0% 0% no-repeat padding-box;
    padding: 3%;
    margin: 5% 0%;
}

.view-less-review .view-less-review-btn {
    font-size: 16px;
    text-decoration: underline;
    color: #184EDF;
    font-weight: 500;
    border: none;
    background: transparent;
}