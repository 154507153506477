/* B2bSellerDetails CSS Started */

.B2b-Seller-Detail .Background-image {
  background-repeat: no-repeat;
  position: relative;
  height: auto;
  background-size: cover;
  margin-top: 3%;
  margin-bottom: 3%;
}

.B2b-Seller-Detail .Background-image .product-information p.price {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.B2b-Seller-Detail .Background-image .product-information p.quantity {
  color: #000000;
  font-size: 14px;
  margin-bottom: 11px;
}

.B2b-Seller-Detail .Background-image .product-information p.product {
  font-size: 14px;
  color: #1d44ad;
  font-weight: 600;
  margin-bottom: 5px;
}

.B2b-Seller-Detail .Background-image .product-information .border-between {
  border-left: 1px solid #b7b7b7;
  height: 18px;
  margin: 1px 13px;
}

.B2b-Seller-Detail .Background-image .product-information p.product-id {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 11px;
}

.B2b-Seller-Detail .Background-image .product-information .productHead h1 {
  font-size: 16px;
  color: #000000;
  width: 60%;
}

.B2b-Seller-Detail .Background-image .product-information .productHead p {
  font-size: 14px;
  color: #333333;
  margin-bottom: 11px !IMPORTANT;
}

.B2b-Seller-Detail .Background-image .product-information .productHead span {
  font-size: 18px;
  color: #333333;
}

.B2b-Seller-Detail .Background-image .product-information .productHead .heading-share {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.B2b-Seller-Detail .Background-image .product-information .productHead .heading-share .share-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #481e1e29;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 4px 4px;
  margin-left: auto;
  padding: 7px 10px;
  cursor: pointer;
}

.B2b-Seller-Detail .Background-image .product-information .productHead .heading-share .share-btn .share {
  color: #000;
  font-size: 18px;
}

.B2b-Seller-Detail .Background-image .product-information .productHead .heading-share .share-btn:hover .share {
  color: #ff8c28;
  transform: scale(1.2);
  transition: all ease-out 0.3s;
}

.B2b-Seller-Detail .Background-image .product-information .main-price {
  display: flex;
  justify-content: space-between;
}

.B2b-Seller-Detail .Background-image .product-information .main-price button.btn.btn-primary {
  height: 30px;
  background: #f88d28 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 1px 15px;
  border: none;
  font-size: 12px;
  font-weight: 400;
}

.B2b-Seller-Detail .Background-image .product-information .standard-section button.btn.btn-standard {
  background: #ececec 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 2px 11px;
  color: #535252;
  font-size: 12px;
  font-weight: 400;
}

.B2b-Seller-Detail .Background-image .product-information .standard-section .buttons-section .gotoCartbtn {
  background: #e5ecff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #000;
  border: 0;
  font-size: 15px;
  width: 160px;
  margin-right: 15px;
}

.B2b-Seller-Detail .Background-image .product-information .standard-section .buttons-section .gotoCartbtn:hover {
  background: #1d44ad 0% 0% no-repeat padding-box;
  color: #fff;
}

.B2b-Seller-Detail .Background-image .priceSection {
  display: flex;
  font-size: 30px;
  margin-bottom: 15px;
}

.B2b-Seller-Detail .Background-image .priceSection p:nth-of-type(2) {
  text-decoration: line-through;
  color: #808080;
  font-size: 21px;
  margin-top: auto;
  margin-bottom: auto !important;
}

.B2b-Seller-Detail .Background-image .priceSection p {
  margin-right: 15px;
}



.B2b-Seller-Detail .Background-image .product-information .standard-section .buttons-section {
  display: flex;
  justify-content: left;
  margin-top: 3%;
  margin-bottom: 3%;
}

.B2b-Seller-Detail .Background-image .product-information .main-section-tabs {
  display: flex;
}

/* 
.B2b-Seller-Detail
  .Background-image
  .breadcrumb-item
  + .breadcrumb-item::before {
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, ">");
} */

/***************** Swiper CSS ***********/

.B2b-Seller-Detail .Background-image .product-information .main-section-tabs .background-color-sofa {
  width: 58px;
  height: 58px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  padding: 8px 7px;
  border-radius: 5px;
  margin: 0px 0px 0px 9px;
}

.B2b-Seller-Detail .Background-image .product-information .main-section-tabs img.product {
  height: 42px;
  width: 42px;
}

.B2b-Seller-Detail .Background-image .product-information .main-section-tabs .nav-item {
  margin-left: 12px;
}

.B2b-Seller-Detail .Background-image .background-product-detail {
  min-width: 83%;
  height: 399px;
  /* background: #f8f8f8 0% 0% no-repeat padding-box; */
  border-radius: 10px;
  margin-left: 8px;
  text-align: -webkit-center;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.B2b-Seller-Detail .Background-image .main .gallery-imgs img.inner-img {
  width: 52px;
  height: 52px;
}

.B2b-Seller-Detail .Background-image .main .gallery-imgs {
  text-align: center;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper .swiper-slide {
  height: 76px !important;
  width: 76px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-bottom: 6px !important;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper-mobile-view .swiper-slide.swiper-slide {
  height: 76px !important;
  width: 76px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin-bottom: 6px !important;
}

/* .B2b-Seller-Detail .Background-image .product-content {
      padding-top: 20px;
  } */

.B2b-Seller-Detail .Background-image .main {
  display: flex;
}

.B2b-Seller-Detail .Background-image .main .silder-btn {
  position: relative;
}

.B2b-Seller-Detail .Background-image .main .sidebar-swiper .silder-btn .back-btn {
  /* content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/up.png"); */
  width: 20px;
  height: 20px;
  font-size: 15px;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  background-color: #e9e9e9;
  color: #a2a2a2;
  bottom: 388px;
  z-index: 99;
  left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.B2b-Seller-Detail .Background-image .main .sidebar-swiper .silder-btn .next-btn {
  /* content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/down.png"); */
  width: 20px;
  height: 20px;
  margin: 10px;
  font-size: 15px;
  cursor: pointer;
  position: absolute;
  background-color: #e9e9e9;
  color: #a2a2a2;
  bottom: -11px;
  z-index: 99;
  right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.B2b-Seller-Detail .Background-image .swiper-button-prev.swiper-button-disabled,
.B2b-Seller-Detail .Background-image .swiper-button-next.swiper-button-disabled {
  display: none;
}

.B2b-Seller-Detail .Background-image .swiper-button-prev,
.B2b-Seller-Detail .Background-image .swiper-button-next {
  display: none;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper .mySwiper {
  height: 406px !important;
}

.B2b-Seller-Detail .Background-image .main .background-product-detail .product-detail img.product-img {
  width: 100%;
  height: 520px;
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* object-fit: contain; */
}

.B2b-Seller-Detail .Background-image .background-product-detail .product-detail {
  position: relative;
}

.B2b-Seller-Detail .Background-image .background-product-detail .product-detail .silder-btn {
  position: absolute;
  display: flex;
  width: 100%;
  top: 47%;
  padding: 0px 15px;
  justify-content: space-between;
}

.B2b-Seller-Detail .Background-image .background-product-detail .product-detail .silder-btn .back-btn {
  /* content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/left.png"); */
  width: 30px;
  height: 30px;
  /* margin: 10px; */
  cursor: pointer;
  /* position: absolute; */
  /* bottom: 342px; */
  z-index: 99;
  /* right: 43px; */
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 23px;
  color: #a2a2a2;
}

.B2b-Seller-Detail .Background-image .background-product-detail .product-detail .silder-btn .next-btn {
  /* content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/right.png"); */
  width: 30px;
  height: 30px;
  /* margin: 10px; */
  background-color: #e9e9e9;
  display: flex;
  border-radius: 50%;
  font-size: 23px;
  color: #a2a2a2;
  cursor: pointer;
  /* position: absolute; */
  /* bottom: 342px; */
  z-index: 99;
  /* right: 4px; */
  justify-content: center;
  align-items: center;
}

.B2b-Seller-Detail .Background-image .main .sidebar-swiper-mobile-view {
  display: none;
}

.B2b-Seller-Detail .Background-image .colourSection .form-check-input:checked[type="radio"] {
  background: red;
  border: 0;
}

.B2b-Seller-Detail .Background-image .colourSection {
  display: flex;
  margin-top: 2%;
}

.B2b-Seller-Detail .Background-image .colourSection .colorBox {
  margin-right: 15px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.red-box {
  background-color: red;
}

.blue-box {
  background-color: blue;
}

.green-box {
  background-color: green;
}

.yellow-box {
  background-color: yellow;
}

/* .B2b-Seller-Detail .Background-image .main .sidebar-swiper-mobile-view .silder-btn .back-btn {
    content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/up.png");
    width: 15px;
    height: 15px;
    margin: 10px;
    cursor: pointer;
    position: absolute;
    bottom: 342px;
    z-index: 99;
    left: -5px;
    transform: rotate(-90deg);
} */

.B2b-Seller-Detail .Background-image .main .sidebar-swiper-mobile-view {
  display: none;
  position: relative;
}

.B2b-Seller-Detail .Background-image .main .sidebar-swiper-mobile-view .silder-btn {
  display: none;
  justify-content: space-between;
  position: absolute;
  top: 32%;
  width: 100%;
}

.B2b-Seller-Detail .Background-image .main .sidebar-swiper-mobile-view .silder-btn .back-btn {
  /* content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/up.png"); */
  width: 20px;
  height: 20px;
  /* margin: 10px; */
  cursor: pointer;
  /* position: absolute; */
  /* bottom: 23px; */
  z-index: 102;
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 15px;
  color: #a2a2a2;
  /* left: -5px; */
  /* transform: rotate(-90deg); */
}

.B2b-Seller-Detail .Background-image .main .sidebar-swiper-mobile-view .silder-btn .next-btn {
  /* content: url("../../../../../public/assest/images/B2Bbuyer/B2BSellerDetail/down.png"); */
  width: 20px;
  height: 20px;
  /* margin: 10px; */
  cursor: pointer;
  /* position: absolute; */
  /* bottom: 23px; */
  z-index: 102;
  background-color: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 15px;
  color: #a2a2a2;
  /* right: -18px; */
  /* transform: rotate(-90deg); */
}

/***************** Swiper CSS ***********/

.B2b-Seller-Detail .Background-image .main .background-product-detail .product-detail .swiper {
  /* padding-top: 74px; */
  /* height: 397px; */
}

.B2b-Seller-Detail .Background-image .sidebar-swiper {
  display: block;
}

.B2b-Seller-Detail .Background-image .onImageHeartsec {
  position: relative;
}

.B2b-Seller-Detail .Background-image .onImageHeartsec .productheartIcon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper {
  display: block;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper-mobile-view {
  display: none;
}

.B2b-Seller-Detail .Background-image .sidebar-swiper-mobile-view {
  display: none;
}

/******************** Media Query Started *************************/

.B2b-Seller-Detail svg.star-svg {
  height: 11px;
  width: 11px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .customize button.btn.btn-customize-btn {
  padding: 3px 10px;

  background: #ffd500 0% 0% no-repeat padding-box;
  border-radius: 11px;
  color: #000000;
  font-size: 11px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .star p.rating {
  font-size: 10px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .customize svg.svg-inline--fa.fa-print {
  height: 11px;
  width: 11px;
  cursor: pointer;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .customize svg.svg-inline--fa.fa-share-nodes {
  height: 11px;
  width: 11px;
  cursor: pointer;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .standard-section .buttons-section .circle {
  padding: 5px 8px;
  width: 30px;
  font-size: 14px;
  height: 30px;
  background-color: #ebebeb;
  border-radius: 100px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .Warranty {
  margin: 0px 10px;
  width: 38px;
  height: 38px;
  background-color: #fff4e9;
  border-radius: 100px;
  padding: 6px 10px;
}

.B2b-Seller-Detail .Background-image .sofaImgmain {
  width: 46px;
  height: 46px;
  background: #F8F8F8 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.B2b-Seller-Detail .Background-image .sofaImgmain .sofaPreviewimg {
  width: 34px;
  height: 34px;
}

.B2b-Seller-Detail .Background-image .product-information .standard-section .buttons-section .chat {
  padding: 7px 18px;
  font-size: 13px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 6px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .year {
  text-align: center;
  width: 60px;
  font-size: 10px;
  margin-bottom: 25px !important;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .Warranty img.icon {
  width: 18px;
  height: 20px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .accordion-button:not(.collapsed) {
  color: #000000 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.B2b-Seller-Detail .Background-image .product-content .product-information button.accordion-button {
  font-size: 16px;
  font-weight: 600;
  padding-left: 0;
  position: relative;
}

.B2b-Seller-Detail .Background-image .product-content .product-information button span {
  position: absolute;
  content: ("");
  width: 52%;
  height: 2px;
  background-color: #a7a7a7;
  right: 8%;
  margin: auto 20px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .product {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .product-text {
  color: #000000;
  font-size: 12px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .accordion-item {
  border-radius: 0px !important;
  border-top: 0;
  border-bottom: 0;
  border-left: none;
  border-right: none;
}

.B2b-Seller-Detail .Background-image .accordionHead h3 {
  font-size: 18px;
  color: #333333;
  margin-bottom: 2px;
}

.B2b-Seller-Detail .Background-image .accordionHead p {
  font-size: 15px;
  color: #333333;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .star {
  margin: -4px 5px;
}

.B2b-Seller-Detail .Background-image .accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card {
  height: 428px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .add-cart-box {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: fit-content;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .add-cart-box .minus {
  background-color: #f6f6f6;
  border-right: 1px solid #ccc;
  padding: 0px 9px;
  font-size: 17px;
  border-radius: 5px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .add-cart-box .add {
  background-color: #f6f6f6;
  border-left: 1px solid #ccc;
  padding: 0px 9px;
  font-size: 17px;
  border-radius: 5px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .add-cart-box .quantity {
  background-color: #fff;
  padding: 0px 15px;
  font-size: 17px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card p.amount-text {
  font-size: 11px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .variants {
  height: 22px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #c9c9c9;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .chair-main .chair {
  padding: 8px 8px;
  width: 46px;
  height: 46px;
  background: #e1e1e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: 26px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .Select {
  display: flex;
  justify-content: space-around;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .chair-main .chair img.product-image {
  width: 33px;
  height: 34px;
  /* padding: 0px 1px; */
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .Select .form-check-reverse {
  margin-top: 21px;
  margin-bottom: 47px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .price-main p.price {
  font-size: 11px;
  margin-top: 29px;
  margin-bottom: 55px;
}

.B2b-Seller-Detail .Background-image .product-information .sizeSection {
  display: flex;
  flex-flow: wrap;
}

.B2b-Seller-Detail .Background-image .product-information .sizeSection .sizeNumber {
  border: 0.5px solid #707070;
  border-radius: 3px;
  padding: 3px 5px;
  margin-right: 19px;
  margin-bottom: 6%;
}

.button_slide {
  border: 2px solid #1d44ad;
  box-shadow: inset 0 0 0 0#1D44AD;
  -webkit-transition: ease-out 0.8s;
  -moz-transition: ease-out 0.8s;
  transition: ease-out 0.8s;
  font-size: 12px;
  font-weight: 500;
  color: #000;
}

.slide_down:hover {
  box-shadow: inset 0 -100px 0 0 #1d44ad;
  color: #fff;
}

.slide_down.active {
  color: #fff !important;
  background: #1d44ad;
}

.product-information .Inclusivetxt p {
  font-size: 14px;
  margin-bottom: 8px !important;
}

.B2b-Seller-Detail .Background-image .onImageHeartsec .Overlay_text_holder {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 50%;
  top: 50%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
}




.B2b-Seller-Detail .Background-image .onImageHeartsec .Overlay_text_holder.show {
  opacity: 1;
}

.B2b-Seller-Detail .Background-image .onImageHeartsec .Overlay_text_holder .Overlay_text {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #00000042;
  border: 1px solid #F6F6F6;
  border-radius: 5px;
  opacity: 0.7;
  width: 70%;
  margin: 0px auto;
  padding: 15px 0px;

}

.B2b-Seller-Detail .Background-image .onImageHeartsec .Overlay_text p {
  text-align: center;
  font-weight: 18px;
  font-weight: 500;
  color: #000000;
}

/********** common media query started ***********/
@media (min-width: 0px) and (max-width: 991px) {
  .B2b-Seller-Detail .Background-image .main {
    display: flex;
    flex-direction: column-reverse;
  }

  .B2b-Seller-Detail .Background-image .product-information .productHead .heading-share .share-btn .share {
    font-size: 11px;
  }

  .B2b-Seller-Detail .Background-image .product-information .productHead .heading-share .share-btn {
    padding: 0px 7px;
  }

  .B2b-Seller-Detail .Background-image .onImageHeartsec .Overlay_text p {
    font-size: 11px;
  }

  .B2b-Seller-Detail .Background-image .product-information .sizeSection .sizeNumber {
    margin-right: 5px;
    margin-bottom: 5px;
  }

  .B2b-Seller-Detail .Background-image .background-product-detail {
    height: auto;
    margin-left: 0px;
  }

  .B2b-Seller-Detail .Background-image .sidebar-swiper {
    display: none;
  }

  .B2b-Seller-Detail .Background-image {
    height: auto;
  }

  .B2b-Seller-Detail .Background-image .swiper.swiper-initialized.swiper-vertical.swiper-backface-hidden.mySwiper {
    height: auto;
  }

  .product-information {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .B2b-Seller-Detail .Background-image .background-product-detail .product-detail .silder-btn .back-btn {
    width: 25px;
    height: 25px;
  }

  .B2b-Seller-Detail .Background-image .background-product-detail .product-detail .silder-btn .next-btn {
    width: 25px;
    height: 25px;
  }

  .B2b-Seller-Detail .Background-image .sidebar-swiper-mobile-view {
    display: block !important;
    margin-top: 3%;
  }

  .B2b-Seller-Detail .Background-image .accordionContent .contentHead p {
    font-size: 12px !important;
  }

  .B2b-Seller-Detail .Background-image .accordionContent .contentHead h3 {
    font-size: 13px !important;
  }
}

/*************************** common media query end *************************/

@media (min-width: 0px) and (max-width: 485px) {
  .B2b-Seller-Detail .Background-image .product-content {
    margin-top: 0%;
    padding-top: 20px;
  }

  .B2b-Seller-Detail .Background-image .product-information .heading {
    font-size: 14px;
  }

  .B2b-Seller-Detail .Background-image .product-information .standard-section .buttons-section button.btn.btn-primary {
    padding: 6px 18px;
    font-size: 13px;
    width: 135px;
  }


  .B2b-Seller-Detail .Background-image .product-information .main-price button.btn.btn-primary {
    margin-right: 0%;
  }

  .B2b-Seller-Detail .Background-image .product-information p.product-id {
    font-size: 12px;
  }

  .B2b-Seller-Detail .Background-image .product-information p.quantity {
    font-size: 13px;
  }

  .B2b-Seller-Detail .Background-image .product-information p.product {
    font-size: 13px;
  }

  .B2b-Seller-Detail .Background-image .product-information p.price {
    font-size: 15px;
  }

  .B2b-Seller-Detail .Background-image .product-information .standard-section .buttons-section {
    flex-wrap: unset;
    display: flex;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    padding-bottom: 13px;
  }

  .B2b-Seller-Detail .Background-image .main .sidebar-swiper .silder-btn {
    display: none;
  }

  .B2b-Seller-Detail .Background-image .main .background-product-detail .product-detail img.product-img {
    padding: 0px 24px;
    width: 100%;
    height: 300px;
  }

  .B2b-Seller-Detail .Background-image .main .sidebar-swiper-mobile-view .silder-btn {
    display: flex;
  }

  .B2b-Seller-Detail .Background-image .onImageHeartsec .productheartIcon {
    right: 35px;
    top: 15px;
  }
}

@media (min-width: 486px) and (max-width: 767px) {
  .B2b-Seller-Detail .Background-image .product-information .heading {
    font-size: 15px;
  }

  .B2b-Seller-Detail .Background-image .product-information p.quantity {
    font-size: 13px;
  }

  .B2b-Seller-Detail .Background-image .product-information p.product {
    font-size: 13px;
  }

  .B2b-Seller-Detail .Background-image .product-information p.price {
    font-size: 16px;
  }

  .B2b-Seller-Detail .Background-image .product-information .standard-section .buttons-section button.btn.btn-primary {
    padding: 7px 18px;
    font-size: 13px;
  }



  .B2b-Seller-Detail .Background-image .product-information .main-price button.btn.btn-primary {
    margin-right: 0%;
  }

  .B2b-Seller-Detail .Background-image .main .sidebar-swiper .silder-btn {
    display: none;
  }

  .B2b-Seller-Detail .Background-image .main .sidebar-swiper-mobile-view .silder-btn {
    display: flex;
  }

  .B2b-Seller-Detail .Background-image .onImageHeartsec .productheartIcon {
    top: 15px;
    right: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .B2b-Seller-Detail .Background-image .product-information .heading {
    font-size: 16px;
  }

  .B2b-Seller-Detail .Background-image .swiper-slide {
    /* margin-left: 13px; */
  }

  .B2b-Seller-Detail .Background-image .onImageHeartsec .productheartIcon {
    /* right: 50px; */
  }


  .B2b-Seller-Detail .Background-image .background-product-detail .product-detail .silder-btn {
    padding: 0px 0px;
  }

  .B2b-Seller-Detail .Background-image .main .sidebar-swiper-mobile-view .silder-btn {
    display: flex;
  }

  .B2b-Seller-Detail .Background-image .main .background-product-detail .product-detail img.product-img {
    height: 300px;
    /* object-fit: contain; */
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  .B2b-Seller-Detail .Background-image .background-product-detail {
    min-width: 79%;
  }

  .B2b-Seller-Detail .Background-image .onImageHeartsec .Overlay_text p {
    font-size: 13px;
  }

  .B2b-Seller-Detail .Background-image .main .background-product-detail .product-detail img.product-img {
    width: 100%;
    height: 390px;
  }
}

/****************************** Media Query End ******************************/
.B2b-Seller-Detail svg.star-svg {
  height: 11px;
  width: 11px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .customize button.btn.btn-customize-btn {
  padding: 3px 10px;

  background: #ffd500 0% 0% no-repeat padding-box;
  border-radius: 11px;
  color: #000000;
  font-size: 11px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .star p.rating {
  font-size: 10px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .customize svg.svg-inline--fa.fa-print {
  height: 11px;
  width: 11px;
  cursor: pointer;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .customize svg.svg-inline--fa.fa-share-nodes {
  height: 11px;
  width: 11px;
  cursor: pointer;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .standard-section .buttons-section .circle {
  padding: 5px 8px;
  width: 30px;
  font-size: 14px;
  height: 30px;
  background-color: #ebebeb;
  border-radius: 100px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .Warranty {
  margin: 0px 10px;
  width: 38px;
  height: 38px;
  background-color: #fff4e9;
  border-radius: 100px;
  padding: 6px 10px;
}



.B2b-Seller-Detail .Background-image .product-content .product-information .Warranty img.icon {
  width: 18px;
  height: 20px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .accordion-button:not(.collapsed) {
  color: #000000 !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.B2b-Seller-Detail .Background-image .product-content .product-information button.accordion-button {
  font-size: 16px;
  font-weight: 600;
  padding-left: 0;
  position: relative;
}

.B2b-Seller-Detail .Background-image .product-content .product-information button span {
  position: absolute;
  content: ("");
  width: 60%;
  height: 2px;
  background-color: #a7a7a7;
  right: 8%;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .product {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .product-text {
  color: #000000;
  font-size: 12px;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .accordion-item {
  border-radius: 0px !important;
  border-top: 0;
  border-bottom: 0;
  border-left: none;
  border-right: none;
}

.B2b-Seller-Detail .Background-image .accordionHead h3 {
  font-size: 18px;
  color: #333333;
  margin-bottom: 2px;
}

.B2b-Seller-Detail .Background-image .accordionHead p {
  font-size: 15px;
  color: #333333;
}

.B2b-Seller-Detail .Background-image .product-content .product-information .star {
  margin: -4px 5px;
}

.B2b-Seller-Detail .Background-image .accordion-button:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card {
  height: 428px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .add-cart-box {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: fit-content;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .add-cart-box .minus {
  background-color: #f6f6f6;
  border-right: 1px solid #ccc;
  padding: 0px 9px;
  font-size: 17px;
  border-radius: 5px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .add-cart-box .add {
  background-color: #f6f6f6;
  border-left: 1px solid #ccc;
  padding: 0px 9px;
  font-size: 17px;
  border-radius: 5px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .add-cart-box .quantity {
  background-color: #fff;
  padding: 0px 15px;
  font-size: 17px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card p.amount-text {
  font-size: 11px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .variants {
  height: 22px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #c9c9c9;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .chair-main .chair {
  padding: 8px 8px;
  width: 46px;
  height: 46px;
  background: #e1e1e1 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-top: 8px;
  margin-bottom: 26px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .Select {
  display: flex;
  justify-content: space-around;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .chair-main .chair img.product-image {
  width: 33px;
  height: 34px;
  /* padding: 0px 1px; */
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .Select .form-check-reverse {
  margin-top: 21px;
  margin-bottom: 47px;
}

.B2b-Seller-Detail .Background-image .product-content .Amount-Card .price-main p.price {
  font-size: 11px;
  margin-top: 29px;
  margin-bottom: 55px;
}

.B2b-Seller-Detail .Background-image .product-information .sizeSection {
  display: flex;
  flex-flow: wrap;
}

.B2b-Seller-Detail .Background-image .product-information .sizeSection .sizeNumber {
  border: 0.5px solid #707070;
  border-radius: 3px;
  padding: 3px 5px;
  margin-right: 19px;
  margin-bottom: 6%;
}

.B2b-Seller-Detail .Background-image .button_slide {
  border: 2px solid #1d44ad;
  box-shadow: inset 0 0 0 0#1D44AD;
  -webkit-transition: ease-out 0.8s;
  -moz-transition: ease-out 0.8s;
  transition: ease-out 0.8s;
  font-size: 12px;
  font-weight: 500;
  color: #000;
}

.B2b-Seller-Detail .Background-image .slide_down:hover {
  box-shadow: inset 0 -100px 0 0 #1d44ad;
  color: #fff !important;
}

.B2b-Seller-Detail .Background-image .accordionContent .contentHead h3 {
  font-size: 15px;
  color: #333333;
}

.B2b-Seller-Detail .Background-image .accordionContent .contentHead {
  margin-bottom: 15px;
}

.B2b-Seller-Detail .Background-image .accordionContent .contentHead p {
  font-size: 15px;
  color: #333333;
}

@media screen and (min-width: 0px) and (max-width: 485px) {
  .B2b-Seller-Detail .Background-image .product-content .product-information button span {
    display: none;
  }
}

@media screen and (min-width: 0px) and (max-width: 1200px) {
  .B2b-Seller-Detail .Background-image .product-information .sizeSection {
    overflow-x: scroll;
    margin-bottom: 15px;
  }

  .B2b-Seller-Detail .Background-image .product-information .productHead h1 {
    font-size: 17px;
  }

  .B2b-Seller-Detail .Background-image .product-information .productHead p {
    font-size: 14px;
    padding-bottom: 6px;
  }

  .B2b-Seller-Detail .Background-image .priceSection p:nth-of-type(2) {
    font-size: 14px;
  }

  .B2b-Seller-Detail .Background-image .priceSection {
    font-size: 18px;
  }

  .B2b-Seller-Detail .Background-image .accordionHead h3 {
    font-size: 15px;
  }

  .B2b-Seller-Detail .Background-image .accordionHead p {
    font-size: 13px;
  }

  .B2b-Seller-Detail .Background-image .product-content .product-information button.accordion-button {
    font-size: 13px;
  }

  .B2b-Seller-Detail .Background-image .main .gallery-imgs img.inner-img {
    width: 52px;
    height: 73px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .B2b-Seller-Detail .Background-image .priceSection {
    font-size: 20px;
  }

  .B2b-Seller-Detail .Background-image .product-information .productHead h1 {
    font-size: 17px;
  }

  .B2b-Seller-Detail .Background-image .product-information .productHead p {
    font-size: 14px;
    padding-bottom: 6px;
  }

  .B2b-Seller-Detail .Background-image .priceSection p:nth-of-type(2) {
    font-size: 14px;
  }

  .B2b-Seller-Detail .Background-image .priceSection {
    font-size: 18px;
  }

  .B2b-Seller-Detail .Background-image .accordionHead h3 {
    font-size: 15px;
  }

  .B2b-Seller-Detail .Background-image .accordionHead p {
    font-size: 13px;
  }

  .B2b-Seller-Detail .Background-image .product-content .product-information button.accordion-button {
    font-size: 13px;
  }
}