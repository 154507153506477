.Home_Banner {
  margin-bottom: 2%;
}

.Home_Banner .Home_Banner_Card_Holder .Home_Banner_Card_Slider_Holder .banner_main_img {
  width: 100%;
  margin-bottom: 4%;
  height: 460px;
  object-fit: cover;
}

.Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .Home_Banner_Small_Card_img {
  width: 100%;
  height: 240px;
  border-radius: 10px;
}

.Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .small_card {
  margin-bottom: 15px;
}

.Home_Banner .Home_Banner_Card_Holder .Home_Banner_Long_Card .Home_Banner_Long_Card_img {
  width: 100%;
  height: 498px;
  border-radius: 10px;
}

.Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .smallcardban {
  position: relative;
  transition: 0.3s;
  border-radius: 10px;
}

.Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .smallcardban:hover .overlayss {
  transition: 0.3s;
  height: 100%;
  top: 0%;
  border-radius: 10px;
}

.Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .smallcardban .overlayss {
  position: absolute;
  width: 100%;
  background-color: #1d44ad;
  z-index: 1;
  top: 100%;
  height: 0%;
  transition: 0.3s;
  border-radius: 10px;
  opacity: 0.3;
}

.Home_Banner .Home_Banner_Card_Holder .Home_Banner_Long_Card .longcardban {
  position: relative;
  transition: 0.3s;
  border-radius: 10px;
}

.Home_Banner .Home_Banner_Card_Holder .Home_Banner_Long_Card .longcardban:hover .overlayss {
  transition: 0.3s;
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.Home_Banner .Home_Banner_Card_Holder .Home_Banner_Long_Card .longcardban .overlayss {
  position: absolute;
  width: 0%;
  background-color: #1d44ad;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  transition: 0.3s;
  border-radius: 10px;
  opacity: 0.3;
}

.Home_Banner .Home_Banner_Card_Slider_Holder {
  position: relative;
}

.Home_Banner .silder-btn {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  bottom: 13px;
}

.Home_Banner .silder-btn .back-btn {
  /* width: 32px; */
  /* height: /32px; */
  margin: 0px 17px;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.Home_Banner .silder-btn .next-btn {
  /* width: 32px;
  height: 32px; */
  margin: 0px 17px;
  color: #000;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

@media (min-width: 0px) and (max-width: 320px) {
  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Card_Slider_Holder .banner_main_img {
    height: 150px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Card_Slider_Holder {
    margin-bottom: 15px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .smallcard2 {
    margin-bottom: 15px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Long_Card .Home_Banner_Long_Card_img {
    height: 215px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .Home_Banner_Small_Card_img {
    height: 100px;
  }

  .Home_Banner {
    margin-top: 20%;
  }
}

@media (min-width: 320px) and (max-width: 390px) {
  .Home_Banner {
    margin-top: 16%;
  }
}

@media (min-width: 391px) and (max-width: 405px) {
  .Home_Banner {
    margin-top: 15%;
  }
}

@media (min-width: 321px) and (max-width: 485px) {
  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Card_Slider_Holder .banner_main_img {
    height: 265px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Card_Slider_Holder {
    margin-bottom: 15px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .smallcard2 {
    margin-bottom: 15px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Long_Card .Home_Banner_Long_Card_img {
    height: 315px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .Home_Banner_Small_Card_img {
    height: 150px;
  }
}

@media (min-width: 485px) and (max-width: 767px) {
  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Card_Slider_Holder .banner_main_img {
    height: 320px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .Home_Banner_Small_Card_img {
    height: 200px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Card_Slider_Holder {
    margin-bottom: 15px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .smallcard2 {
    margin-bottom: 15px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Long_Card .Home_Banner_Long_Card_img {
    height: 415px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Long_Card .Home_Banner_Long_Card_img {
    height: 250px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .Home_Banner_Small_Card_img {
    height: 117px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Card_Slider_Holder {
    margin-bottom: 15px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Card_Slider_Holder .banner_main_img {
    height: 251px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Card_Slider_Holder .banner_main_img {
    height: 472px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Small_Card .Home_Banner_Small_Card_img {
    height: 228px;
  }

  .Home_Banner .Home_Banner_Card_Holder .Home_Banner_Long_Card .Home_Banner_Long_Card_img {
    height: 472px;
  }
}