.inqury_form .inquiryformbackground {
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
}

.inqury_form .formcontroltexts {
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0px;
  color: #847C7C;
  padding: 10px;
  opacity: 1;
}

.inqury_form .inqu_button_div {
  text-align: center;
}

.inqury_form .inqu_button_div .form_inqury_button {
  width: 292px;
  max-width: 100%;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 0px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 6px;
  opacity: 1;


  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;


  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  display: inline-block;
  position: relative;

  vertical-align: middle;
  color: black;
  z-index: 1;
  overflow: hidden;
}

.inqury_form .inqu_button_div .form_inqury_button:before {
  /* transitions på :before og :after er ikke støttet i opera og safari ennå */
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  padding-top: 0;
  z-index: -1;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  border-radius: 100%;
  background: #d66800;

  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.inqury_form .inqu_button_div .form_inqury_button:hover:before {
  width: 105%;
  padding-top: 105%;
}

.inqury_form .inqu_button_div .form_inqury_button:hover {

  color: #FFFFFF;
  border: 1px solid #D66800;
}