.main-rating-review {
  margin: 4%;
}

.review .view-more-button {
  border: 1px solid #000000;
  background: #ffffff;
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  padding: 10px 60px;
  margin-top: 3%;
  border-radius: 6px;

  -webkit-transition: color 0.3s ease-in-out;
  -moz-transition: color 0.3s ease-in-out;
  -ms-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;

  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;

  display: inline-block;
  position: relative;

  vertical-align: middle;

  z-index: 1;
  overflow: hidden;
}

.review .review_card_holder {
  height: 260px;
  overflow: hidden;
  overflow-y: auto;
}

.review .view-more-button:before {
  /* transitions på :before og :after er ikke støttet i opera og safari ennå */
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  padding-top: 0;
  z-index: -1;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  border-radius: 100%;
  background: #0c2770;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.review .view-more-button:hover:before {
  width: 105%;
  padding-top: 105%;
}

.review .view-more-button:hover {
  /* background: transparent linear-gradient(180deg, #184EDF 0%, #0C2770 100%) 0% 0% no-repeat padding-box; */
  border-radius: 6px;
  color: #ffffff;
}
