.category-page {
  margin: 3% 0%;
}

.category-page .nav-item-class {
  background: #FFFFFF;
  box-shadow: 0px 3px 11px #00000014;
  border-radius: 10px;
}

.category-page .nav-pills .nav-link {
  color: #000;
  background-color: transparent;
  font-size: 14px;
  border-radius: 0px;
  padding: 0;
  margin: 6px 7px;
}

.category-page .nav-pills .nav-link.active {
  color: #000;
  background-color: transparent;
  border-right: 3px solid #F88D28;
}

.category-page .categories-title {
  font-size: 18px;
  margin-left: 6px;
}


.subcategorytabss .subcat-tabcobt .silder-btn {
  position: relative;
  margin: 20px;
}

.subcategorytabss .subcat-tabcobt .silder-btn img {
  width: 30px;
}

.subcategorytabss .subcat-tabcobt .silder-btn .back-btn {
  position: absolute;
  left: 45%;
  bottom: 0px;
}

.subcategorytabss .subcat-tabcobt .silder-btn .next-btn {
  position: absolute;
  right: 45%;
  bottom: 0px;
}

.subcat-tabcobt {
  height: 100% !important;
}

.subcategorytabss .nav .nav-link {
  padding: auto !important;
  color: #000;
  font-size: 14px !important;
  padding: 8px 15px;
  margin: 0px 5px !important;
}

.subcategorytabss .nav .nav-link.active {
  background-color: #ff8c28;
  color: #fff;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 500;
  border-right: 0px !important;
}

.subcategorytabss  .subcattt{
  font-size: 18px;
  margin-left: 6px;
  margin-top: 8px;
}

.subcategorytabss .subcat-tabcobt .swiper-slide {
  margin: 5px;
}