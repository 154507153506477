.privacy-policy {
    margin: 5% 0;
}

.privacy-policy .heading-holder h1 {
    font-size: 25px;
    text-align: center;
    font-weight: 600;
}

.text-holder p {
    font-size: 14px;
}

@media(min-width:0px) and (max-width:767px) {
    .privacy-policy .heading-holder h1 {
        font-size: 18px;
    }
}