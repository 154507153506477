.footer-section {
  background: transparent linear-gradient(293deg, #ffd5ae 0%, #ffffff 100%) 0% 0% no-repeat padding-box;
}

.footer-section .footermains .footer-col {
  margin: auto;
}

.footer-section .footermains {
  border-bottom: 1px solid #000;
}

/* .footer-section .toptext {
  display: flex;
  align-items: center;
  height: 75px;
  width: 100%;justify-content: center;
} */

.footer-section .toptext {
  text-align: center;
  width: 100%;
}

.footer-section .toptext .follows {
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #0e0e0e;
  margin-bottom: 0;
  opacity: 1;
}

.footer-section .toptext .follow_link {
  color: #1d44ad;
}

.footer-section .toptext .footer-logos {
  width: 201px;
  height: 79px;
}

.footer-section .toptext .footericnss {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  background: #ff8c28 0% 0% no-repeat padding-box;
  color: #fff;
  font-size: 13px;
  opacity: 1;
}

.footer-section .linktxt {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  padding-bottom: 10px;
  text-align: center;
}

.footer-section .phoneicn {
  margin-right: 7px;
}

.footer-section .addresdetails {
  display: flex;
  margin-bottom: 11px;
}

.footer-section .addresicns {
  font-size: 16px;
  margin-right: 10px;
}

.footer-section .bottomtext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
}

.footer-section .checkss {
  color: green;
  margin-right: 5px;
}

.footer-section .copyright {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
  margin-bottom: 0;
}

.footer-section .coprightspan {
  font-weight: 600;
}

.footer-section a {
  text-decoration: none !important;
  color: #000;
}

.footer_bottom_logos {
  width: 115px;
  height: 45px;
}

@media (min-width: 0px) and (max-width: 767px) {
  .footer-section .toptext .footer-logos {
    width: 130px;
    height: 50px;
  }

  .footer-section .toptext {
    height: 55px;
  }

  .footer-section .toptext .follows {
    font-size: 12px;
  }

  .footer-section .toptext .footericnss {
    margin-left: 7px;
    width: 25px;
    height: 26px;
    font-size: 10px;
  }

  .footer-section .linktxt {
    font-size: 11px;
    margin-bottom: 8px;
    text-align: start;
  }

  .footer-section .copyright {
    font-size: 9px;
    margin-right: 2px;
  }

  .footer-section .bottomtext {
    padding: 8px 0px;
  }

  .footer_bottom_logos {
    width: 100px;
    height: 38px;
  }
}

@media (min-width: 767px) and (max-width: 991px) {
  .footer-section .toptext .footer-logos {
    width: 130px;
    height: 50px;
  }

  .footer-section .toptext {
    height: 55px;
  }

  .footer-section .toptext .follows {
    font-size: 19px;
  }

  .footer-section .toptext .footericnss {
    margin-left: 7px;
    width: 25px;
    height: 26px;
    font-size: 11px;
  }

  .footer-section .linktxt {
    font-size: 11px;
    margin-bottom: 8px;
  }

  .footer-section .copyright {
    font-size: 9px;
    margin-right: 2px;
  }

  .footer-section .bottomtext {
    padding: 8px 0px;
  }

  .footer_bottom_logos {
    width: 110px;
    height: 43px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .footer-section .toptext .footer-logos {
    width: 180px;
    height: 65px;
  }

  .footer-section .toptext {
    height: 70px;
  }

  .footer-section .toptext .follows {
    font-size: 20px;
  }

  .footer-section .linktxt {
    font-size: 13px;
  }

  .footer-section .copyright {
    font-size: 11px;
  }

  .footer-section .bottomtext {
    padding: 8px 0px;
  }
}


.footer {
  background: #000 0% 0% no-repeat padding-box;
  padding-top: 79px;

}

.footer .headlogo {
  width: 148px;
  height: 79px;
}

.footer .nav {
  margin-top: 23px;
}

.footer .nav-link {
  color: #FFF;
  font-size: 14px;
  padding-left: 0%;
  /* margin: 0px 11px; */
}

.footer .nav-link:hover {
  color: #FFF;
  font-size: 14px;
  padding-left: 0%;
  /* margin: 0px 11px; */
}

.footer .nav-link.active {
  color: #FFF;
  font-size: 14px;
  padding-left: 0%;
  /* margin: 0px 11px; */
}

.footer .nav-link:focus {
  color: #FFF;
  font-size: 14px;
  padding-left: 0%;
  /* margin: 0px 11px; */
}

.footer a {
  color: #fff;
}

.footer .text-area-loaction {
  margin-top: 20px;
}

.footer p {
  font-size: 12px;
  color: #FFF;
}

.footer .email-box .form-control {
  border-radius: 0%;
  padding: 0px;
  border: none;
  font-size: 15px;
  color: #000;
  background-color: transparent;
  padding-left: 10px;
}

.footer .email-box .form-control::placeholder {
  font-size: 15px;
  color: #000;
}

.footer .email-box .form-control:focus {
  box-shadow: none;
}

.footer .email-box .input-group-text {
  border: none;
  background-color: transparent;
  padding-right: 0px;
}



.footer-bannermain {
  position: relative;
}



.footer .address-holder .text-holder {
  margin: 23px 0px;
}

.footer .address-holder .text-holder p {
  font-size: 13px;
  color: #fff;
  margin-bottom: 0%;
}

.footer .address-holder .social-linksss {
  display: flex;
  margin-left: auto;
  margin-top: 34px;
}



.footer .address-holder .social-linksss .footer-soc-logo-holder {
  width: 20px;
  height: 20px;
  margin: 0px 5px;
}

.footer .address-holder .social-linksss .sociallogo {
  width: 20px;
  height: 20px;
  margin: 0px 5px;
}

.footer .address-holder .social-linksss .social-icon {
  background-color: #FFF;
  border-radius: 25px;
  /* padding: 6px; */
  margin-right: 10px;
  width: 29px;
  height: 29px;
}

.footer .address-holder .social-linksss .social-icon .fa-logo {
  margin-top: 8px;
  color: #000;
}



.footer .copyright-holder {
  border-top: 1px solid #fff;
}

.footer .copyright-holder p {
  color: #fff;
  font-size: 13px;
  margin-top: 25px ;
  margin-bottom: 25px !important;
}



.footer .location-holder {
  display: flex;
  justify-content: center;
  margin-top: 26px;
  margin-bottom: 26px;

}

.footer .location-holder .call-holder span {
  color: #FFF;
  font-size: 13px;
}

.footer .location-holder .call-holder .call-icon {
  color: #FFF;
}

@media(min-width:992px) and (max-width:1200px) {
  .footer .nav-link:hover {
    margin: 0px 4px;
  }

  .footer .nav-link.active {
    margin: 0px 4px;
  }

  .footer .nav-link {
    margin: 0px 4px;
  }

  .footer .nav-link:focus {
    margin: 0px 4px;
  }
}

@media(min-width:768px) and (max-width:991px) {
  .footer .nav-link:hover {
    margin: 0px 4px;
  }

  .footer .nav-link.active {
    margin: 0px 4px;
  }

  .footer .nav-link {
    margin: 0px 4px;
  }

  .footer .nav-link:focus {
    margin: 0px 4px;
  }

  .footer .nav {
    margin-top: 46px;
  }

  .footer .address-holder .social-icon {
    margin-top: 19px;
    margin-bottom: 16px;
  }
}

@media(min-width:0px) and (max-width:767px) {
  .footer .nav-link:hover {
    margin: 0px 0px;
    padding: 0px 6px;
    font-size: 13px;
  }

  .footer .nav-link.active {
    margin: 0px 0px;
    padding: 0px 6px;
    font-size: 13px;
  }

  .footer .nav-link {
    margin: 0px 0px;
    padding: 0px 6px;
    font-size: 13px;
  }

  .footer .nav-link:focus {
    margin: 0px 0px;
    padding: 0px 6px;
    font-size: 13px;
  }

  .footer .nav {
    margin-top: 9px;
    margin-bottom: 19px;
  }

  .footer .address-holder .social-icon {
    margin-top: 19px;
    margin-bottom: 16px;
  }

  .footer .location-holder {
    display: unset;
  }


}

/* subscribe-banner-css start*/
.footer-banner {
  margin-top: -170px;
}

.footer-bannermain {
  position: relative;
  margin-bottom: 38px;
}

.footer-bannermain .ban-contentmain {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.footer-bannermain .banconts {
  padding-top: 20px;
  padding-left: 70px;
  padding-right: 10px;
}

.footer-bannermain .banconts .newssetl {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.footer-bannermain .banconts .getss {
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #333333;
  opacity: 1;
}

.footer .email-box .input-group {
  padding: 0px 5px;
  background-color: #fff;
  border-radius: 25px;
}

.footer-bannermain .subscribe-btn {
  border-radius: 21px;
  /* height: 38px; */
}

.footer-bannermain .footerimg {
  width: 100%;
  height: 202px;
  border-radius: 10px;
}

@media (min-width: 0px) and (max-width: 991px) {
  .footer-bannermain .banconts {
    padding-top: 15px;
    padding-left: 10px;
  }

  .footer-bannermain .banconts .newssetl {
    font-size: 16px;

  }
}