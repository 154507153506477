.main-reviews-sec {
    background: #FFF;
    box-shadow: 0px 3px 6px #00000029;
    margin-top: 2%;
    padding: 10px;
    border-radius: 10px;
}

.main-reviews-sec .main-user-img {
    /* background: #00000029; */
    /* box-shadow: 0px 3px 6px #00000029; */
    border-radius: 50%;
    box-shadow: 0px 26px 37px #00000012;
}

.main-reviews-sec .main-user-img .user-img {
    /* width: 100%; */
    width: 90px;
    height: 90px;
    border-radius: 50%;
    padding: 10px;
    object-fit: contain;
}

.main-reviews-sec .user-name {
    font-size: 18px;
    font-weight: 600;
}

.main-reviews-sec .reviews-txtt {
    font-size: 14px;
}


/* caed temp css */
.main-reviews-sec  .review_card_main{
    display: flex;
    align-items: center;
    position: relative;
}

.main-reviews-sec .userdetaless{
    width: 100%;
    padding-left: 10px;
    /* background: #FFF;
    box-shadow: 0px 3px 6px #00000029; */
    /* padding-left: 50px; */
}

/* .main-user-img{
    position: absolute;
    left: 0px;
} */
/* Media */

@media(min-width:0px) and (max-width:576px) {
    .main-reviews-sec .main-user-img .user-img {
        height: 64px;
        width: 64px;
    }

    .main-reviews-sec .reviews-txtt {
        font-size: 12px;
    }

    .main-reviews-sec .reviewclass span {
        font-size: 15px !important;
    }

    .main-reviews-sec .user-name {
        font-size: 14px;
    }
}

@media(min-width:577px) and (max-width:767px) {
    .main-reviews-sec .main-user-img .user-img {
        height: 64px;
        width: 64px;
    }

    .main-reviews-sec .reviews-txtt {
        font-size: 12px;
    }

    .main-reviews-sec .reviewclass span {
        font-size: 15px !important;
    }

    .main-reviews-sec .user-name {
        font-size: 14px;
    }
}

@media(min-width:768px) and (max-width:991px) {
   
    .main-reviews-sec .reviews-txtt {
        font-size: 12px;
    }

    .main-reviews-sec .reviewclass span {
        font-size: 15px !important;
    }

    .main-reviews-sec .user-name {
        font-size: 14px;
    }
}

