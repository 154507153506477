
.browsecatogary_section {
  padding-top: 50px;
}


.browsecatogary_section .cardsimgmain {
  position: relative;
  transition: 0.3s;
}

.browsecatogary_section .cardsimgmain .hoverovarlay {
  position: absolute;
  background: transparent linear-gradient(180deg, #00000000 0%, #000000fa 100%)
    0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  width: 100%;
  height: 0px;
  bottom: 0;
}

.browsecatogary_section .cardsimgmain:hover .hoverovarlay {
  height: 70%;
  transition: 0.4s;
}

.browsecatogary_section .cardhead {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
}

.browsecatogary_section .cardsimgmain .cardhead .cardheadtext {
  color: #ffffff;
  font-size: 34px;
  letter-spacing: 0px;
  font-weight: 400;
  text-shadow: 0px 3px 6px #00000029;
  transition: 0.3s;
  display: none;
}

.browsecatogary_section .cardsimgmain:hover .cardheadtext {
  display: block;
  transform: scale(1.1);
  transition: 0.5s;
}

.browsecatogary_section .cardsimgmain:hover .cardhead .cardheadtext {
  font-size: 30px;
 
 
  color: #ffffff;
  /* text-shadow: 0px 3px 6px #00000029; */
  opacity: 1;
  transition: 0.3s;
}

.browsecatogary_section .browse_heading {
  font-size: 40px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #343434;
  opacity: 1;
  text-align: center;
}

.browsecatogary_section .cardsimgmain .bbroswecardimg {
  width: 100%;
  height: 470px;
  border-radius: 20px;
  transition: 0.3s;
  overflow: hidden;
  object-fit: cover;
}

.browsecatogary_section .cardsimgmain .broswecardimg2 {
  width: 100%;
  height: 220px;
  border-radius: 20px;
  transition: 0.3s;
  overflow: hidden;
  object-fit: cover;
}

.browsecatogary_section .cardsimgmain .cardsbigimgdiv {
  width: 100%;
  height: 470px;
  border-radius: 20px;
  transition: 0.3s;
  overflow: hidden;
}

.browsecatogary_section .cardsimgmain .cardsimgdiv {
  width: 100%;
  height: 220px;
  transition: 0.3s;
  border-radius: 20px;
  overflow: hidden;
}

.browsecatogary_section .cardsimgmain:hover .bbroswecardimg {
  transition: 0.3s;
  transform: scale(1.1);
}
.browsecatogary_section .cardsimgmain:hover .broswecardimg2 {
  transition: 0.3s;
  overflow: hidden;
  transform: scale(1.1);
}

.browsecatogary_section .cardmainimg1 {
  margin-bottom: 30px;
}

@media (min-width: 0px) and (max-width: 575px) {
  .browsecatogary_section {
    padding-top: 10px;
}
  
  .browsecatogary_section .browse_heading {
    font-size: 23px;
  }
  .browsecatogary_section .cardsimgmain .cardsbigimgdiv {
    margin-bottom: 15px;
  }
  .browsecatogary_section .cardmainimg1 {
    margin-bottom: 0px;
  }
  .browsecatogary_section .cardsimgmain .cardsimgdiv {
    margin-bottom: 15px;
  }
  .browsecatogary_section .cardsimgmain:hover .cardhead .cardheadtext {
    font-size: 22px;
  }
  .browsecatogary_section .cardsimgmain .cardhead .cardheadtext {
    font-size: 25px;
  }
  .browsecatogary_section .cardsimgmain .cardsbigimgdiv {
    height: 400px;
  }
  .browsecatogary_section .cardsimgmain .bbroswecardimg {
    height: 400px;
  }
}

@media (min-width: 576px) and (max-width: 991px) {
  .browsecatogary_section {
    padding-top: 15px;
}
  .browsecatogary_section .browse_heading {
    font-size: 27px;
  }
  .browsecatogary_section .cardsimgmain .cardsbigimgdiv {
    margin-bottom: 15px;
  }
  .browsecatogary_section .cardmainimg1 {
    margin-bottom: 30px;
  }
  .browsecatogary_section .cardsimgmain .cardsimgdiv {
    margin-bottom: 15px;
  }
  .browsecatogary_section .cardsimgmain:hover .cardhead .cardheadtext {
    font-size: 22px;
  }
  .browsecatogary_section .cardsimgmain .cardhead .cardheadtext {
    font-size: 25px;
  }
  .browsecatogary_section .cardsimgmain .cardsbigimgdiv {
    height: 400px;
  }
  .browsecatogary_section .cardsimgmain .bbroswecardimg {
    height: 400px;
  }
  .browsecatogary_section .cardmainimg1 {
    margin-bottom: 20px;
  }
  .browsecatogary_section .cardsimgmain .cardsimgdiv {
    height: 190px;
  }
  .browsecatogary_section .cardsimgmain .broswecardimg2 {
    height: 190px;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .browsecatogary_section .browse_heading {
    font-size: 30px;
  }

  .browsecatogary_section .cardsimgmain:hover .cardhead .cardheadtext {
    font-size: 26px;
  }
  .browsecatogary_section .cardsimgmain .cardhead .cardheadtext {
    font-size: 28px;
  }
}
