.inquiry-form .girl-imggggg {
    width: 100%;
}

.inquiry-form .form-label {
    font-size: 16px;
    font-weight: 600;
}

.inquiry-form .modal-header {
    border-bottom: 1px solid #FFF;
}

.inquiry-form .form-control {
    /* background: #F5F5F5 0% 0% no-repeat padding-box; */
    border-radius: 6px;
    /* border: none; */
    height: 48px;
    font-size: 12px;
    color: #8F9094;
}

.inquiry-form .form-control:focus {
    box-shadow: unset;
}

.modal_section .Toastify__close-button {}

.tonstify_section button.Toastify__close-button.Toastify__close-button--light {
    display: none;
}

.tonstify_section .Toastify__progress-bar {
    display: none;
}

.tonstify_section .Toastify__progress-bar--bg {
    display: none;
}

.tonstify_section .Toastify__toast-theme--light {

    background-color: #52B100 !important;
    color: #fff !important;
    text-align: center !important;
    margin-right: 20px;
    font-size: 16px;
    padding: 0px 50px;
    font-weight: 500;
}

.tonstify_section .Toastify__toast-container--top-right {
    top: 0 !important;
    left: 0;
    backdrop-filter: blur(9px);
    background: #ffffff26;
    width: 100% !important;
    height: 100%;
    text-align: -webkit-right;

}

.tonstify_section .Toastify__toast-theme--light {
    background: var(--toastify-color-light);
    color: var(--toastify-text-color-light);
    width: fit-content;
}

/* Media */

@media(min-width:0px) and (max-width:767px) {
    .inquiry-form .form-label {
        font-size: 14px;
    }

    .inquiry-form .girl-imggggg {
        margin-bottom: 10%;
    }
}